import { apiClient } from "@netgame/openapi";

import useAppInitData from "./useAppInitData";

const useTournamentSubscribe = (props: { isSubscribed?: boolean; id?: number }) => {
	const { data } = useAppInitData();

	const loadingSubscribtions = ref(false);

	const isSubscribed = computed(() => props.isSubscribed || data.value?.TournamentsSubscriptions?.includes(props.id!));

	const handleSubscribe = (id: number) => {
		if (isSubscribed.value) {
			return;
		}

		apiClient({
			path: "/rest/tournaments/{id}/subscribe/",
			method: "get",
			parameters: { path: { id } },
			options: {
				onRequest: () => {
					loadingSubscribtions.value = true;
				},
				onResponse: ({ response }) => {
					loadingSubscribtions.value = false;
					if (response._data.success) {
						if (data.value?.TournamentsSubscriptions) {
							data.value.TournamentsSubscriptions.push(id);
						}
					}
				},
				onRequestError: () => {
					loadingSubscribtions.value = false;
				},
				onResponseError: () => {
					loadingSubscribtions.value = false;
				}
			}
		});
	};

	return {
		loadingSubscribtions,
		isSubscribed,
		handleSubscribe
	};
};

export default useTournamentSubscribe;
